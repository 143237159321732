<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="gsLists" :loading="loading" :scroll="{ x: 1600 }" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="gsLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/GSSJDetail', query: { id: record.INDIVIDUAL_ID, num: record.xuhao, type: 'edit' } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
            <div class="add-item">
                <div @click="$router.push({ path: '/ReportForms/GSSJDetail', query: { type: 'add', id: '', num: '' } })">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;">增加一行
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
    { title: '序号', width: 100, dataIndex: 'xuhao', key: 'xuhao' },
    { title: '工号', width: 100, dataIndex: 'gonghao', key: 'gonghao' },
    { title: '姓名', width: 100, dataIndex: 'xingming', key: 'xingming' },
    { title: '证照类型', width: 100, dataIndex: 'zhengzhaoleixing', key: 'zhengzhaoleixing' },
    { title: '证照号码', width: 180, dataIndex: 'zhenghaohaoma', key: 'zhenghaohaoma' },
    { title: '申报表', width: 100, dataIndex: 'shenbaobiao', key: 'shenbaobiao' },
    { title: '所得项目', width: 100, dataIndex: 'suodexiangmu', key: 'suodexiangmu' },
    { title: '所得期间起', width: 150, dataIndex: 'suodeqijianqi', key: 'suodeqijianqi' },
    { title: '所得期间止', width: 150, dataIndex: 'suodeqijianzhi', key: 'suodeqijianzhi' },
    { title: '收入额', width: 100, dataIndex: 'shourue', key: 'shourue' },
    { title: '基本养老保险费', width: 150, dataIndex: 'jibenyanglaobaoxianfei', key: 'jibenyanglaobaoxianfei' },
    { title: '基本医疗保险费', width: 150, dataIndex: 'jibenyiliaobaoxianfei', key: 'jibenyiliaobaoxianfei' },
    { title: '失业保险费', width: 150, dataIndex: 'shiyebaoxianfei', key: 'shiyebaoxianfei' },
    { title: '住房公积金', width: 150, dataIndex: 'zhufanggongjijin', key: 'zhufanggongjijin' },
    { title: '年金', width: 100, dataIndex: 'nianjin', key: 'nianjin' },
    { title: '商业健康保险', width: 150, dataIndex: 'shangyejiankangbaoxian', key: 'shangyejiankangbaoxian' },
    { title: '投资抵扣', width: 150, dataIndex: 'touzidikou', key: 'touzidikou' },
    { title: '其他扣除', width: 100, dataIndex: 'qitakouchu', key: 'qitakouchu' },
    { title: '免税所得', width: 100, dataIndex: 'mianshuisuode', key: 'mianshuisuode' },
    { title: '税前扣除项目合计', width: 150, dataIndex: 'shuiqiankouchuxiangmuheji', key: 'shuiqiankouchuxiangmuheji' },
    { title: '减除费用', width: 150, dataIndex: 'jianchufeiyong', key: 'jianchufeiyong' },
    { title: '准予扣除的捐赠额', width: 150, dataIndex: 'zhunyukouchudejuanzenge', key: 'zhunyukouchudejuanzenge' },
    { title: '允许扣除的税费', width: 150, dataIndex: 'runxukouchudeshuifei', key: 'runxukouchudeshuifei' },
    { title: '财产原值', width: 100, dataIndex: 'caichanyuanzhi', key: 'caichanyuanzhi' },
    { title: '应缴纳所得额', width: 150, dataIndex: 'yingjiaonasuodee', key: 'yingjiaonasuodee' },
    { title: '税率', width: 100, dataIndex: 'shuilv', key: 'shuilv' },
    { title: '速算扣除数', width: 150, dataIndex: 'susuankouchushu', key: 'susuankouchushu' },
    { title: '应纳税额', width: 100, dataIndex: 'yingnashuie', key: 'yingnashuie' },
    { title: '减免税额', width: 100, dataIndex: 'jianmianshuie', key: 'jianmianshuie' },
    { title: '应扣缴税额', width: 150, dataIndex: 'yingkoujiaoshuie', key: 'yingkoujiaoshuie' },
    { title: '已扣缴税额', width: 150, dataIndex: 'yikoujiaoshuie', key: 'yikoujiaoshuie' },
    { title: '应补(退)税额', width: 150, dataIndex: 'yingbutuishuie', key: 'yingbutuishuie' },
    {
        title: '操作',
        key: 'operation',
        align: 'center',
        fixed: 'right',
        width: 100,
        slots: { customRender: 'action' }
    }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'addXXStatus',
            'y'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getGSSJList(this.y)
            }
        },
        y: function (newVal) {
          if (newVal) {
            this.getGSSJList(this.y)
          }
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            loading: false,
            gsLists: [],
            pagination: {},
            page: 1,
            pageCount: 10
        }
    },
    methods: {
        // 获取个税数据列表
        getGSSJList (y) {
            this.loading = true
            this.gsLists = []
            this.$store.dispatch('getGSSJList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.gsLists.push({
                                'key': parseInt(i) + 1,
                                'INDIVIDUAL_ID': datas[i].INDIVIDUAL_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'gonghao': datas[i].GONGHAO,
                                'xingming': datas[i].UNAME,
                                'zhengzhaoleixing': datas[i].DOCUTYPE,
                                'zhenghaohaoma': datas[i].DOCUCODE,
                                'shenbaobiao': datas[i].SHENBAOBIAO,
                                'suodexiangmu': datas[i].SUODEPROJECT,
                                'suodeqijianqi': datas[i].RECEIVESTART,
                                'suodeqijianzhi': datas[i].RECEIVEEND,
                                'shourue': datas[i].RECEIVEMONEY,
                                'jibenyanglaobaoxianfei': datas[i].JBYLBXF,
                                'jibenyiliaobaoxianfei': datas[i].JBYANGLAOBXF,
                                'shiyebaoxianfei': datas[i].SYBXF,
                                'zhufanggongjijin': datas[i].ZFGJJ,
                                'nianjin': datas[i].NIANJIN,
                                'shangyejiankangbaoxian': datas[i].SYJKBX,
                                'touzidikou': datas[i].TZDK,
                                'qitakouchu': datas[i].OTHERKOUCHU,
                                'mianshuisuode': datas[i].MIANSHUISD,
                                'shuiqiankouchuxiangmuheji': datas[i].SQKCXMHJ,
                                'jianchufeiyong': datas[i].JIANCHUFY,
                                'zhunyukouchudejuanzenge': datas[i].ZYKCJZE,
                                'runxukouchudeshuifei': datas[i].YXKCSF,
                                'caichanyuanzhi': datas[i].ZCYZ,
                                'yingjiaonasuodee': datas[i].YJNSDE,
                                'shuilv': datas[i].SUILV,
                                'susuankouchushu': datas[i].SSKCS,
                                'yingnashuie': datas[i].YNSE,
                                'jianmianshuie': datas[i].JIANMIANSE,
                                'yingkoujiaoshuie': datas[i].YKJSE,
                                'yikoujiaoshuie': datas[i].YKJSEA,
                                'yingbutuishuie': datas[i].YBTSE,
                                'tm': new Date().getTime()
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.gsLists = []
                    }
                } else {
                    this.pagination.total = 0
                    this.gsLists = []
                    this.$message.error('获取列表失败，请稍后重试！')
                }
            }).catch(e => {
                this.loading = false
                this.$message.error('系统异常，获取列表失败，请稍后重试！')
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getGSSJList(this.y)
        },
        // 删除单条个税数据
        onDel (item) {
            this.$store.dispatch('deleteGSSJItem', { INDIVIDUAL_ID: item.INDIVIDUAL_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getGSSJList(this.y)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getGSSJList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .add-item{
            margin-top: 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>
